(function () {
	'use strict';

	angular
		.module('common')
		.factory('csvData', ['seedcodeCalendar', 'utilities', csvData]);

	function csvData(seedcodeCalendar, utilities) {
		return {
			csvToObject: csvToObject,
			csvToSettings: csvToSettings,
			csvToDataList: csvToDataList,
			csvToMembers: csvToMembers,
			csvToResources: csvToResources,
			csvToStatuses: csvToStatuses,
			csvToEventSource: csvToEventSource,
			csvToSources: csvToSources,
		};
		function csvToObject(csv, propertyList, outputIsArray, mutate) {
			var csvData = [],
				tempDelimiterReplace = '!~delimiterSub~!',
				output = [];
			if (!csv) {
				return output;
			}

			function replaceEscapedQuotes(data, state) {
				return data.replace(new RegExp(tempDelimiterReplace, 'g'), '"'); //Customized For SeedCode to allow quotes as long as they are escaped
			}

			// replace(/\\\",\\\"/g, tempSeparatorReplace).replace(/\\\"/g, tempDelimiterReplace)

			//Temporarily substitute escaped quotes
			csv = csv
				.replace(/\\\"/g, tempDelimiterReplace)
				.replace(/\\\“/g, '“')
				.replace(/\\\”/g, '”'); //Customized for SeedCode so we can temporarily replace escaped quotes

			csvData = $.csv.toArrays(csv, {onParseValue: replaceEscapedQuotes});
			for (var i = 0; i < csvData.length; i++) {
				var eventResult = {};

				var csvEvent = $.isArray(csvData[i])
					? csvData[i]
					: [csvData[i]];
				//loop each item in the csv event and set them as object properties
				for (var ii = 0; ii < csvEvent.length; ii++) {
					eventResult[propertyList[ii]] = csvEvent[ii];
				}
				if (mutate) {
					eventResult = mutate(eventResult);
				}
				if (outputIsArray && eventResult) {
					output.push(eventResult);
				} else if (!outputIsArray) {
					output = eventResult;
				}
			}
			return output;
		}

		function csvToSettings(csv) {
			var csvMap = [
				'activationHash',
				'defaultDate',
				'dateFormat',
				'databaseDateFormat',
				'view',
				'scrollTime',
				'slotDuration',
				'firstDay',
				'maxAllDayEvents',
				'weekNumbers',
				'titleDayFormat',
				'titleWeekFormat',
				'titleMonthFormat',
				'titleResourceFormat',
				'dateStringFormat',
				'dateStringShortFormat',
				'timeFormat',
				'showTimeMeridian',
				'minTime',
				'maxTime',
				'defaultTimedEventDuration',
				'nextDayThreshold',
				'resourceColumns',
				'defaultSidebarTab',
				'hideMenuItems',
				'showAdminSettings',
				'showSidebar',
				'lockSidebar',
				'defaultEventColor',
				'fluidMonths',
				'compressedView',
				'slotEventOverlap',
				'statusPreFilter',
				'filemakerBuild',
				'weekends',
				'editTimeDuration',
				'returnSub',
				'lazyFetching',
				'primaryCalendar',
				'resourceDays',
				'horizonDays',
				'distances',
				'noFilterLabel',
				'gridTimeColumns',
				'horizonBreakoutField',
				'sharePrivileges',
				'linkedUserToken',
				'accountName',
				'snapToMonth',
				'showRemoteNotifications',
				'deviceID',
				'showMeasureSettings',
				'showMeasureThreshold',
				'measureThreshold',
				'measureThresholdLineColor',
				'measureThresholdFillColor',
				'measureThresholdFillOpacity',
				'measureType',
				'measureField',
				'measureNumberFormat',
				'measureNumberLabelBefore',
				'measureNumberLabelAfter',
				'measureDecimalSymbol',
				'measureDecimalPlaces',
				'measureThousandsSeparator',
				'measureAggregate',
			];
			var mutateSettings = function (setting) {
				for (var property in setting) {
					if (
						property === 'scrollTime' ||
						property === 'minTime' ||
						property === 'maxTime' ||
						property === 'defaultTimedEventDuration' ||
						property === 'slotDuration' ||
						property === 'editTimeDuration'
					) {
						var value = setting[property].split(':');
						for (var i = 0; i < value.length; i++) {
							if (value[i].length < 2) {
								value[i] = '0' + value[i];
							}
						}
						setting[property] = value.join(':');
					} else if (
						property === 'weekNumbers' ||
						property === 'showTimeMeridian' ||
						property === 'showAdminSettings' ||
						property === 'showSidebar' ||
						property === 'lockSidebar' ||
						property === 'fluidMonths' ||
						property === 'compressedView' ||
						property === 'slotEventOverlap' ||
						property === 'weekends' ||
						property === 'lazyFetching' ||
						property === 'distances' ||
						property === 'snapToMonth' ||
						property === 'showMeasureSettings' ||
						property === 'showMeasureThreshold'
					) {
						if (!setting[property].length) {
							setting[property] = undefined;
						} else {
							setting[property] = isTrue(setting[property]);
						}
					} else if (
						property === 'defaultSidebarTab' &&
						setting[property] === 'home'
					) {
						setting[property] = 'mini-calendars';
					} else if (
						property === 'hideMenuItems' ||
						property === 'statusPreFilter'
					) {
						setting[property] = setting[property].split('\n');
					}
				}
				return setting;
			};
			var result = csvToObject(csv, csvMap, false, mutateSettings);
			result.sourceTypes = {filemakerClient: {id: 1}};
			return result;
		}

		function csvToDataList(csv) {
			var csvMap = ['id', 'name'];
			return csvToObject(csv, csvMap, true);
		}

		function csvToMembers(csv) {
			var csvMap = [
				'firstName',
				'lastName',
				'admin',
				'readOnly',
				'allowSharing',
			];
			var mutateThis = function (resource) {
				return resource;
			};
			var settings = seedcodeCalendar.get('settings');
			var config = seedcodeCalendar.get('config');
			var noFilterLabel =
				config.noFilterLabel ||
				settings.noFilterLabel ||
				defaults.noFilterLabel;
			// var members = csvToObject(csv, csvMap, true, mutateThis);
			var member = {};

			member.account = settings.accountName;
			member.admin = settings.showAdminSettings;
			member.allowSharing = true;

			return member;
		}

		function csvToResources(csv) {
			var csvMap = ['name', 'shortName'];
			var mutateThis = function (resource) {
				resource.id = utilities.generateUID();
				resource.status = {selected: false};
				return resource;
			};
			var settings = seedcodeCalendar.get('settings');
			var defaults = seedcodeCalendar.get('defaults');
			var config = seedcodeCalendar.get('config');
			var noFilterLabel =
				config.noFilterLabel ||
				settings.noFilterLabel ||
				defaults.noFilterLabel;
			var resources = csvToObject(csv, csvMap, true, mutateThis);

			return resources;
		}

		function csvToStatuses(csv) {
			var csvMap = ['name', 'color'];
			var mutateStatus = function (status) {
				var config = seedcodeCalendar.get('config');
				if (!status.color) {
					status.color = config.defaultEventColor;
				}
				status.status = {
					// selected: true
				};
				status.originalName = status.name;
				status.id = utilities.generateUID();
				return status;
			};
			var settings = seedcodeCalendar.get('settings');
			var defaults = seedcodeCalendar.get('defaults');
			var config = seedcodeCalendar.get('config');
			var noFilterLabel =
				config.noFilterLabel ||
				settings.noFilterLabel ||
				defaults.noFilterLabel;
			var statuses = csvToObject(csv, csvMap, true, mutateStatus);
			//Add a no status option so we can view items with no status selected
			//Write our statuses to our statuses object

			return statuses;
		}

		function csvToEventSource(csv) {
			var csvMap = [
				'eventSource',
				'eventID',
				'allDay',
				'start',
				'end',
				'title',
				'titleEdit',
				'description',
				'resource',
				'status',
				'contactID',
				'contactName',
				'projectID',
				'projectName',
				'location',
				'tags',
			];

			var mutateEvent = function (event) {
				return event.eventSource;
			};
			//Return our event object with mutations applied
			return csvToObject(csv, csvMap, true, mutateEvent);
		}

		function csvToSources(csv) {
			var csvMap = [
				'id',
				'name',
				'enabled',
				'externalEdits',
				'customActions',
				'hideItems',
				'readOnly',
				'backgroundColor',
				'customFields',
				'eventActions',
				'allowDayback',
			];

			var fieldMap = {
				eventSource: 'eventSource',
				eventID: 'eventID',
				allDay: 'allDay',
				start: 'start',
				end: 'end',
				title: 'title',
				titleEdit: 'titleEdit',
				description: 'description',
				resource: 'resource',
				status: 'status',
				contactID: 'contactID',
				contactName: 'contactName',
				projectID: 'projectID',
				projectName: 'projectName',
				location: 'location',
				tags: 'tags',
			};

			var customFieldPositionMap = [
				'field',
				'name',
				'formatas',
				'numberLabel',
				'numberLabelPosition',
				'decimalCharacter',
				'decimalPlaces',
				'thousandsSeparator',
				'list',
				'allowMultiple',
			];

			var mutateSources = function (source) {
				var fieldSeparator = '!~!';
				//Assign a source template (currently this is only filemaker script source id 1)
				source.sourceTypeID = 1;
				source.backgroundColor =
					source.backgroundColor ||
					seedcodeCalendar.get('config').defaultEventColor;
				source.externalEdits =
					source.externalEdits == 1 || source.externalEdits == 'true'
						? true
						: false;
				source.enabled =
					source.enabled == 1 || source.enabled == 'true'
						? true
						: false;
				source.readOnly =
					source.readOnly == 1 || source.readOnly == 'true'
						? true
						: false;
				source.allowDayback =
					source.allowDayback == 1 || source.allowDayback == 'true'
						? true
						: false;
				source.hideItems = source.hideItems.split('\n');

				var unusedMap = {};
				for (var i = 0; i < source.hideItems.length; i++) {
					//Fix items that come in as lowercase as this is how we handle these out of DBKFM
					if (
						source.hideItems[i] === 'contact' ||
						source.hideItems[i] === 'contactname'
					) {
						unusedMap.contactName = true;
					} else if (
						source.hideItems[i] === 'project' ||
						source.hideItems[i] === 'projectname'
					) {
						unusedMap.projectName = true;
					} else if (source.hideItems[i] === 'allday') {
						unusedMap.allDay = true;
					} else {
						//Standard lowercase items
						unusedMap[source.hideItems[i]] = true;
					}
				}
				source.unusedMap = unusedMap;

				source.fieldMap = fieldMap;
				source.customActions = applyCustomActions(source);
				source.eventActions = applyEventActions(source);
				source.customFields = applyCustomFields(source);

				return source;

				function applyCustomActions(source) {
					var tempSeparatorReplace = '!~separatorSub~!';
					var tempListSeparatorReplace = '!~listSeparatorSub~!';
					var actionResult = {};
					var eventTypeResult;
					var actionProperties;
					var eventTypes;
					var name;
					var url;
					var className;
					var eventType;

					if (!source.customActions) {
						return;
					}

					//Check for empty parameters
					if (
						!source.customActions
							.replace(new RegExp(fieldSeparator, 'g'), '')
							.trim()
					) {
						return;
					}

					//Replace commas within the square brackets for list items and also create an array of custom fields
					var customActions = source.customActions
						.replace(
							/,(?=((?!\[).)*?\])/g,
							tempListSeparatorReplace
						)
						.replace(/\',\'/g, tempSeparatorReplace)
						.split('\n');
					for (var i = 0; i < customActions.length; i++) {
						actionProperties =
							customActions[i].split(fieldSeparator);
						name = actionProperties[0]
							? actionProperties[0].trim()
							: '';
						url = actionProperties[1]
							? actionProperties[1].trim()
							: '';
						className = actionProperties[2]
							? actionProperties[2].trim()
							: '';
						eventTypes = actionProperties[3]
							? actionProperties[3]
									.replace(
										new RegExp(tempSeparatorReplace, 'g'),
										','
									)
									.replace(
										new RegExp(
											tempListSeparatorReplace,
											'g'
										),
										','
									)
									.trim()
							: null;

						if (!name && !url) {
							//if the name and url are blank we will not consider this a valid action
							continue;
						}

						if (eventTypes) {
							eventTypes = eventTypes
								.replace(/[[\]]/g, '')
								.split(',');
							eventTypeResult = {};
							for (var ii = 0; ii < eventTypes.length; ii++) {
								eventType = eventTypes[ii].trim();
								if (eventType) {
									eventTypeResult[
										eventTypes[ii].trim().toLowerCase()
									] = true;
								}
							}
						}
						actionResult[i] = {
							id: i,
							name: name,
							url: url,
							class: className,
							eventType: eventTypeResult,
						};
					}

					return actionResult;
				}

				function applyEventActions(source) {
					var tempSeparatorReplace = '!~separatorSub~!';
					var tempListSeparatorReplace = '!~listSeparatorSub~!';
					var actionResult = {};
					var eventTypeResult;
					var actionProperties;
					var eventTypes;
					var type;
					var url;
					var preventDefault;
					var eventType;

					if (!source.eventActions) {
						return;
					}

					//Check for empty parameters
					if (
						!source.eventActions
							.replace(new RegExp(fieldSeparator, 'g'), '')
							.trim()
					) {
						return;
					}

					//Replace commas within the square brackets for list items and also create an array of custom fields
					var eventActions = source.eventActions
						.replace(
							/,(?=((?!\[).)*?\])/g,
							tempListSeparatorReplace
						)
						.replace(/\',\'/g, tempSeparatorReplace)
						.split('\n');
					for (var i = 0; i < eventActions.length; i++) {
						actionProperties =
							eventActions[i].split(fieldSeparator);
						type = actionProperties[0]
							? getEventActionType(actionProperties[0].trim())
							: '';
						url = actionProperties[1]
							? actionProperties[1].trim()
							: '';
						preventDefault =
							actionProperties[2] !== undefined
								? isTrue(actionProperties[2].trim())
								: '';
						eventTypes = actionProperties[3]
							? actionProperties[3]
									.replace(
										new RegExp(tempSeparatorReplace, 'g'),
										','
									)
									.replace(
										new RegExp(
											tempListSeparatorReplace,
											'g'
										),
										','
									)
									.trim()
							: null;

						if (!type && !url) {
							//if the type and url are blank we will not consider this a valid action
							continue;
						}

						if (eventTypes) {
							eventTypes = eventTypes
								.replace(/[[\]]/g, '')
								.split(',');
							eventTypeResult = {};
							for (var ii = 0; ii < eventTypes.length; ii++) {
								eventType = eventTypes[ii].trim();
								if (eventType) {
									eventTypeResult[
										eventTypes[ii].trim().toLowerCase()
									] = true;
								}
							}
						}
						actionResult[i] = {
							id: i,
							type: type,
							url: url,
							preventDefault: preventDefault,
							eventType: eventTypeResult,
						};
					}

					return actionResult;
				}

				function applyCustomFields(source) {
					var tempSeparatorReplace = '!~separatorSub~!';
					var tempListSeparatorReplace = '!~listSeparatorSub~!';
					var fieldResult = {};

					if (!source.customFields) {
						return;
					}

					//Check for empty parameters
					if (
						!source.customFields
							.replace(new RegExp(fieldSeparator, 'g'), '')
							.trim()
					) {
						return;
					}

					//Replace commas within the square brackets for list items and also create an array of custom fields. Replace double comma as this indicates comma as number separator in DBKFM.
					var customFields = source.customFields
						.replace(
							/,(?=((?!\[).)*?\])/g,
							tempListSeparatorReplace
						)
						.replace(/\',\'/g, tempSeparatorReplace)
						.split('\n');
					//Breakout fields and remove extra spaces
					for (var i = 0; i < customFields.length; i++) {
						if (!customFields[i]) {
							continue;
						}

						var fields = customFields[i].split(fieldSeparator);
						//If the first two parameters are missing we won't consider this a valid field as field and name have not been mapped
						if (!fields[0] && !fields[1]) {
							continue;
						}
						var id = 'zzzz' + i; //Sort custom fields to bottum of fieldmap
						fieldResult[id] = {
							id: id,
						};

						for (var ii = 0; ii < fields.length; ii++) {
							var field = fields[ii]
								.replace(
									new RegExp(tempSeparatorReplace, 'g'),
									','
								)
								.replace(
									new RegExp(tempListSeparatorReplace, 'g'),
									','
								);
							fieldResult[id][customFieldPositionMap[ii]] = field
								? field.trim()
								: null;

							//Remove brackets from our list of items
							if (
								customFieldPositionMap[ii] === 'list' &&
								fieldResult[id][customFieldPositionMap[ii]]
							) {
								fieldResult[id][customFieldPositionMap[ii]] =
									fieldResult[id][
										customFieldPositionMap[ii]
									].replace(/[[\]]/g, '');
							}
							//Lower case our format so we match
							else if (
								customFieldPositionMap[ii] === 'formatas' &&
								fieldResult[id][customFieldPositionMap[ii]]
							) {
								fieldResult[id][customFieldPositionMap[ii]] =
									fieldResult[id][
										customFieldPositionMap[ii]
									].toLowerCase();
							}
							//Set allow multiple to boolean value if set
							else if (
								customFieldPositionMap[ii] ===
									'allowMultiple' &&
								field
							) {
								fieldResult[id][customFieldPositionMap[ii]] =
									isTrue(
										fieldResult[id][
											customFieldPositionMap[ii]
										]
									);
							}
						}
					}
					return fieldResult;
				}
			};

			return csvToObject(csv, csvMap, true, mutateSources);
		}

		function isTrue(value) {
			if (value == 1 || value === 'true') {
				return true;
			} else if (value == 0 || value === 'false') {
				return false;
			}
		}

		function getEventActionType(value) {
			var eventActionTypes = seedcodeCalendar.get('eventActionTypes');
			if (!value) {
				return;
			}

			value = value.toLowerCase();

			for (var i = 0; i < eventActionTypes.length; i++) {
				if (
					value === eventActionTypes[i].id.toLowerCase() ||
					value === eventActionTypes[i].label.toLowerCase()
				) {
					return eventActionTypes[i].id;
				}
			}
		}
	}
})();
